import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import {
  setAgentId,
  getAgentId,
  removeAgentId,
  setRefreshToken,
  getRefreshToken,
  removeRefreshToken,
  setExpiresIn,
  getExpiresIn,
  removeExpiresIn,
  setLoginTime,
  getLoginTime,
  removeLoginTime,
  getStaffId,
  setStaffId,
  removeModifyPs
} from '@/utils/storage_session';
import { setToken, removeToken, getToken } from '@/utils/cookie_auth';
import { getUserApi, loginApi, logoutApi, refreshTokenApi, getIssueUserRootApi, getIssueRootApi } from '@/api/login';
import { PermissionModule } from '@/store/modules/permission'
import router, { resetRouter } from '@/router';
// 声明user模块的state变量类型
export interface IUserState {
  token: string|any;
  refreshToken: string|any;
  expiresIn: string;
  loginTime: string;
  name: string;
  nameEn: string;
  nickname: string;
  fkAgentId: number | string;
  studentIdIssue: number | string;
  fkPlatformType: string;
  currentPlatform: string;
  privateFilesUrl: string;
  publicFilesUrl: string;
  agentCompanyName: string;
  companyId: number;
  rolesDatas: any[];
}

/**
 * 通过命名空间的形式来使用module，这里的namespaced值即为user
 * store 为主入口页对应的整个vuex模块的store
 */
@Module({ dynamic: true, store, name: 'user'})
class User extends VuexModule implements IUserState {
  public fkAgentId = getAgentId();
  public staffId = getStaffId();
  public token = getToken();
  public refreshToken = getRefreshToken();
  public expiresIn = getExpiresIn();
  public loginTime = getLoginTime();
  public loginId = '';
  public name = '';
  public nameEn = '';
  public nickname = '';
  public companyId = -1;
  public studentIdIssue = -1;
  public fkPlatformType = '';
  public publicFilesUrl = '';
  public privateFilesUrl = '';
  public currentPlatform = '';
  public agentCompanyName = '';
  public bmsLoginId = '';
  public pageIndexSelectYear = '0';
  public bmsLogin = false;
  public issueInnerAccount = false;
  public isShowAppStatusPermission = false;
  public personInfo = {}
  public rolesDatas = [];
  public onlineAgentUserId='';

  get getToken() {
    return this.token;
  }

  // 一旦使用@Mutation装饰某一函数后, 函数内的this上下文即指向当前的state
  @Mutation
  public SET_TOKEN_INFO(tokenInfo: any) {
    this.staffId = tokenInfo.staff_id;
    this.token = tokenInfo.access_token;
    this.expiresIn = tokenInfo.expires_in;
    this.loginTime = tokenInfo.login_time;
    setStaffId(this.staffId)
    setToken(this.token);
    setExpiresIn(this.expiresIn);
    setLoginTime(this.loginTime);
    
  }

  @Mutation
  public SET_REFRESH_TOKEN(refreshToken: string) {
    this.refreshToken = refreshToken;
    setRefreshToken(this.refreshToken);
  }

  @Mutation
  public SET_NAME(name: string) {
    this.name = name;
  }
  @Mutation
  public SET_LOGINID(loginId: string) {
    this.loginId = loginId;
  }
  @Mutation
  public SET_NAMEEN(nameEn: string) {
    this.nameEn = nameEn;
  }

  @Mutation
  public SET_NICKNAME(nickname: string) {
    this.nickname = nickname;
  }

  @Mutation
  public SET_AGENTID(agentId: string) {
    this.fkAgentId = agentId;
    setAgentId(agentId)
  }

  @Mutation
  public SET_STUDENT_ID_ISSUE(studentIdIssue: number) {
    this.studentIdIssue = studentIdIssue;
  }

  @Mutation
  public SET_PLATFORMTYPE(platformType: string) {
    this.fkPlatformType = platformType;
  }

  @Mutation
  public SET_CUR_PLATFORMTYPE(currentPlatform: string) {
    this.currentPlatform = currentPlatform;
  }

  @Mutation
  public SET_PRIVATE_FILES_URL(privateFilesUrl: string) {
    this.privateFilesUrl = privateFilesUrl;
  }

  @Mutation
  public SET_PUBLIC_FILES_URL(publicFilesUrl: string) {
    this.publicFilesUrl = publicFilesUrl;
  }

  @Mutation
  public SET_AGENT_COMPANY_NAME(company: string) {
    this.agentCompanyName = company;
  }

  @Mutation
  public SET_BMS_LOGIN_ID(bmsLoginId: string) {
    this.bmsLoginId = bmsLoginId;
  }

  @Mutation
  public SET_PAGE_INDEX_SELECT_YEAR(year: string) {
    this.pageIndexSelectYear = year;
  }

  @Mutation
  public SET_BMS_LOGIN(bmsLogin: boolean) {
    this.bmsLogin = bmsLogin;
  }

  @Mutation
  public SET_ISSUE_INNER_ACCOUNT(issueInnerAccount: boolean) {
    this.issueInnerAccount = issueInnerAccount;
  }

  @Mutation
  public SET_USER_ROOT(userRoot: boolean) {
    this.isShowAppStatusPermission = userRoot;
  }

  @Mutation
  public SET_COMPANY_ID(companyId: string) {
    this.companyId = parseInt(companyId, 10);
  }
  @Mutation
  public SET_ISSUE_ROOT(issueRoot: any) {
    this.rolesDatas = issueRoot;
  }
  @Mutation
  public ONLINE_AGENT_USER_ID(onlineAgentUserId: string) {
    this.onlineAgentUserId = onlineAgentUserId;
  }

  @Action
  public RefreshUserInfo(user: any) {
    this.SET_NAME(user.name);
    this.SET_LOGINID(user.loginId);
    this.SET_NAMEEN(user.nameEn);
    this.SET_NICKNAME(user.nickname);
    this.SET_AGENTID(user.fkAgentId);
    this.SET_COMPANY_ID(user.company);
    this.SET_BMS_LOGIN(user.bmsLogin);
    this.SET_ISSUE_INNER_ACCOUNT(user.issueInnerAccount);
    this.SET_PLATFORMTYPE(user.fkPlatformType);
    this.SET_PUBLIC_FILES_URL(user.publicFilesUrl);
    this.SET_STUDENT_ID_ISSUE(user.studentIdIssue);
    this.SET_CUR_PLATFORMTYPE(user.currentPlatform);
    this.SET_PRIVATE_FILES_URL(user.privateFilesUrl);
    this.SET_AGENT_COMPANY_NAME(user.agentCompanyName);
    this.SET_BMS_LOGIN_ID(user.bmsLoginId);
    this.ONLINE_AGENT_USER_ID(user.onlineAgentUserId);
  }

  @Action
  public ResetToken() {
    this.SET_REFRESH_TOKEN('');
    removeToken();
    removeExpiresIn();
    removeLoginTime();
    removeRefreshToken();
    this.SET_TOKEN_INFO({
      expires_in: '',
      login_time: '',
      access_token: '',
      staff_id: ''
    });
  }

  @Action
  public ResetAgentId() {
    this.SET_AGENTID('');
    removeAgentId();
  }

  @Action
  public async GetUser() {
    try {
      const { data } = await getUserApi();
      const userInfo = data.data;
      this.RefreshUserInfo(userInfo);
    } catch (err) {
      // return Promise.reject();
    }
  }

  @Action
  public async GetUserRoot() {
    try {
      const { data } = await getIssueUserRootApi();
      this.SET_USER_ROOT(data.data.isShowAppStatusPermission)
    } catch (err) {
      //
    }
  }
  @Action
  public async GetIssueRoot() {
    try {
      const { data } = await getIssueRootApi();
      const roles: any = [];
      if (data.datas && data.datas.length) {
        this.SET_ISSUE_ROOT(data.datas)
        data.datas.forEach((item: any) => {
          for (const v in item) {
            if (!roles.includes(v)) {
              roles.push(v)
            }
          }
        });
      }
      PermissionModule.GenerateRoutes(roles);
      router.addRoutes(PermissionModule.dynamicRoutes);
    } catch (err) {
      //
    }
  }

  @Action
  public async RefreshToken() {
    try {
      const { data }: any = await refreshTokenApi(this.refreshToken);
      this.SET_TOKEN_INFO(data);
    } catch (err) {
      return Promise.reject();
    }
  }

  @Action
  public async Login(userObj: { username: string, password: string}) {
    const params = {
      ...userObj,
      grant_type: 'password',
      requestUrlType: location.host
    }
    try {
      const { data }: any = await loginApi(params);
      this.SET_TOKEN_INFO(data);
      this.SET_REFRESH_TOKEN(data.refresh_token);
    } catch (err: any) {
      // 当用户没有邀请码10086，或邀请码无效时10087，拦截登录，需填入正确的邀请码才可登录
      if (['10086', '10087'].includes(err.error_description)) {
        return Promise.reject({ hasInvitationCode: false });
      }
    }
  }

  @Action
  public async BmsAgentLogin(params: any) {
    params = {
      ...params,
      grant_type: 'password',
      type: 'avatarByKey',
      fkPlatformType: 'get_issue',
      requestUrlType: location.host,
    }
    try {
      const { data } = await loginApi(params);
      this.SET_TOKEN_INFO(data);
      this.SET_REFRESH_TOKEN(data.refresh_token);
    } catch (err) {
      return Promise.reject();
    }
  }

  @Action
  public async LogOut() {
    try {
      await logoutApi();
      this.SET_NAME('');
      this.SET_NAMEEN('');
      this.SET_NAMEEN('');
      this.SET_NICKNAME('');
      this.SET_AGENTID('');
      this.SET_BMS_LOGIN(false);
      this.SET_ISSUE_INNER_ACCOUNT(false);
      this.SET_STUDENT_ID_ISSUE(-1);
      this.SET_CUR_PLATFORMTYPE('');
      this.SET_PLATFORMTYPE('');
      this.ResetToken();
      this.ResetAgentId();
      this.SET_ISSUE_ROOT([])
      resetRouter();
      removeModifyPs()
    } catch (err) {
      return Promise.reject();
    }
  }
}

export const UserModule = getModule(User);
