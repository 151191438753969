
import i18n from '@/lang';
import ContactNumberLabel from './ContactNumberLabel.vue';
import SubmitBtn from '@/components/Common/SubmitBtn.vue';
import CustomSelect from '@/components/Common/CustomSelect.vue';
import repeatStudentDialog from './repeat_student_dialog.vue';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { IStudentFormData } from '@/interface/studentManagement';
import {
  getAppCountryApi,
  getAreaSateApi,
  getAreaCityApi,
  getRegionOptionsApi,
  getPhoneRegionsApi,
  getStudentAncestryApi,
  getModerLanguageApi,
  saveOrUpdateStudentInfoApi,
  getRegisterDataApi,
  getAreaRegionByCountryId,
  validateStudentInfoApi,
  getIsUseSharedPathApi,
} from '@/api/student-management';
import { getAgentUserInfoByIdApi } from '@/api/login';
import { Form as ElForm } from 'element-ui';
import { UserModule } from '@/store/modules/user';
import { hasChinese,checkEmail2,checkEmailList } from '@/utils/validate';
import { FileAnalysisModule } from '@/store/modules/file_analysis';

@Component({
  name: 'studentFormInfo',
  components: {
    SubmitBtn,
    CustomSelect,
    ContactNumberLabel,
    repeatStudentDialog,
  }
})
export default class extends Vue {

  @Prop({ type: String, default: 'student-register' }) public pageType!: string;

  get companyId() {
    return UserModule.companyId;
  }

  get getTitleOptions() {
    const femaleTitleOptions = [{ value: 1, label: 'Mr. 先生' }];
    const maleTitleOptions = [
      { value: 0, label: 'Ms 女士' },
      { value: 2, label: 'Miss 小姐' },
      { value: 3, label: 'Mrs 夫人' },
    ];
    let titleOptions = [...femaleTitleOptions, ...maleTitleOptions];
    switch (this.stuFormData.gender) {
      case 0:
        titleOptions = maleTitleOptions;
        break;
      case 1:
        titleOptions = femaleTitleOptions;
        break;
    }
    return titleOptions || [];
  }

  get defaultBirthdayDate() {
    const nowDate = new Date();
    const before18Year = new Date().getFullYear() - 18;
    nowDate.setFullYear(before18Year);
    return nowDate;
  }
  get bmsLoginId(){
    return UserModule.bmsLoginId
  }
  get staffId(){
    return UserModule.staffId
  }
  get onlineAgentUserId(){
    return UserModule.onlineAgentUserId;
  }
  @Watch('bmsLoginId')
  changeBmsLoginId(){
    if(this.bmsLoginId){
      this.validateShareParthRequired();
    }
  }
  // 重复学生
  public repeatStudentList:any=[];
  public temporarySave = false;
  public status: number | null = null;
  public stuId: number | null = null;
  public placeholderForHtml = ' '
  public saveLoading = false;
  public saveDisabled = false;
  public regionNumOptions: any[] = [];
  public countryOptions: any[] = [];
  public areaOption: any[] = []; // 英国专属区域
  public stateOptions: any[] = [];
  public cityOptions: any[] = [];
  public regionOptions: any[] = [];
  public ancestryOptions: any[] = [];
  public languageOptions: any[] = [];
  // 出生日期提示控制
  public birthdayFlag:boolean=false;
  // 验证共享链接是否必填
  public shareParthRequired:any=false;
  public stuFormData: IStudentFormData = {
    nameZh: '',
    lastName: '', // 姓
    firstName: '', // 名
    gender: '', // 性别
    birthday: '', // 生日
    phoneRegion: '',
    phoneNumber: '',
    email: '',
    title: '', // 称谓
    emailPassword: '',
    fkStudentAppCountryIdFrom: '',
    fkAreaRegionIdFrom: '',
    fkAreaStateIdFrom: '',
    fkAreaCityIdFrom: '',
    fkAreaCityDivisionIdFrom: '',
    isLivedSinceBirth: true, // 是否自出生便在此居住 // 0否1是
    fkStudentAppCountryIdLive: '', // 居住国家
    migrationTime: '', // 移居时间
    stayingRightTime: '', // 取居留权时间
    contactPostcode: '',
    contactAddress1: '',
    contactAddress2: '',
    fkStudentAppCountryId: '', // 学生国籍
    fkStudentAppAncestryId: '', // 学生血统
    fkStudentAppMotherLanguageId: '', // 学生母语
    fkStudentAppCountryIdBirth: '', // 出生国家
    cityBirth: '', // 出生城市
    isHavePassport: false, // 是否有护照
    fkStudentAppCountryIdPassport: '', // 签发国家或地区
    passportIssueDate: '', // 签发日期
    passportIssueExpried: '', // 有效期至
    passportNumber: '', // 护照号码
    followUpPhoneCode1: '',
    followUpPhoneNumber1: '', // 跟进人电话号码1
    followUpEmail1: '', // 跟进人电邮地址1
    followUpPhoneCode2: '',
    followUpPhoneNumber2: '',
    followUpEmail2: '',
    followUpPhoneCode3: '',
    followUpPhoneNumber3: '',
    followUpEmail3: '',
    id: '',
    fkAgentId: '',
    remark: '',
    fkGuidAndTypeKey:{},// 传入：文件GUID和文件类型
    sharedPath:'',
    isPaymentOnBehalf:false// 是否代付
  };

  @Ref('stuform') public stuform!: ElForm;

  public stuFormRules: any = {
    nameZh: [
      { required: true, validator: this.checkIsEmpty, trigger: 'blur' },
    ],
    lastName: [
      { required: true, validator: this.checkIsEmpty, trigger: 'blur' },
      { validator: this.checkHasChinese, trigger: 'blur' },
    ],
    firstName: [
      { required: true, validator: this.checkIsEmpty, trigger: 'blur' },
      { validator: this.checkHasChinese, trigger: 'blur' },
    ],
    gender: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: 'change' },
    ],
    title: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: 'change' },
    ],
    birthday: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: 'blur' },
      { validator: this.checkBirthDay, triggle: 'change'},
      { validator: this.checkSameInfo, triggle: 'blur'},
    ],
    phoneNumber: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: 'blur' },
      { validator: this.checkPhoneNumber, trigger: 'blur' },
    ],
    phoneRegion: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: 'change' },
      { validator: this.checkPhoneRegion, trigger: 'change' },
    ],
    email: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: 'blur' },
      { type: 'email', message: this.$t('validate.emailValid'), trigger: ['blur', 'change'] },
      { validator: this.checkHasChinese, trigger: 'blur' },
    ],
    emailPassword: [
      { validator: this.checkHasChinese, trigger: 'blur' },
    ],
    fkStudentAppCountryIdFrom: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: ['change', 'blur'] },
      { validator: this.checkHasChinese, trigger: 'blur' },
    ],
    fkAreaStateIdFrom: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: ['change', 'blur'] },
      { validator: this.checkHasChinese, trigger: 'blur' },
    ],
    fkAreaCityIdFrom: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: ['change', 'blur'] },
      { validator: this.checkHasChinese, trigger: 'blur' },
    ],
    fkAreaRegionIdFrom: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: 'change' },
      { validator: this.checkHasChinese, trigger: 'blur' },
    ],
    contactPostcode: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: 'blur' },
      { validator: this.checkContactPostcode, trigger: 'blur' },
    ],
    contactAddress1: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: 'blur' },
      { validator: this.checkHasChinese, trigger: 'blur' },
      { validator: this.checkAddrLength, trigger: 'blur' }
    ],
    contactAddress2: [
      { validator: this.checkHasChinese, trigger: 'blur' },
      { validator: this.checkAddrLength, trigger: 'blur' }
    ],
    followUpPhoneCode1: [
      { required: !this.temporarySave, validator: this.checkRegion, trigger: 'change' }
    ],
    // followUpPhoneCode2: [
    //   { validator: this.checkPhoneNumber, trigger: 'blur' },
    // ],
    // followUpPhoneCode3: [
    //   { validator: this.checkPhoneNumber, trigger: 'blur' },
    // ],
    followUpPhoneNumber1: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: 'blur' },
      { validator: this.checkContactPhoneNumber, trigger: 'blur' }
    ],
    followUpPhoneNumber2: [
      { validator: this.checkContactPhoneNumber, trigger: 'blur' }
    ],
    followUpPhoneNumber3: [
      { validator: this.checkContactPhoneNumber, trigger: 'blur' }
    ],
    followUpEmail1: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: 'blur' },
      { validator: this.rulesEmail,trigger: ['blur', 'change'] },
      { validator: this.checkContactEmail, trigger: 'blur' },
      { validator: this.checkHasChinese, trigger: 'blur' },
    ],
    followUpEmail2: [
      { validator: this.rulesEmail,trigger: ['blur', 'change'] },
      { validator: this.checkContactEmail, trigger: 'blur' },
      { validator: this.checkHasChinese, trigger: 'blur' },
    ],
    followUpEmail3: [
      { validator: this.rulesEmail,trigger: ['blur', 'change'] },
      { validator: this.checkContactEmail, trigger: 'blur' },
      { validator: this.checkHasChinese, trigger: 'blur' },
    ],
    isHavePassport: [
      { required: !this.temporarySave, validator: this.checkRadioNull, trigger: 'change' }
    ],
    isLivedSinceBirth: [
      { required: !this.temporarySave, validator: this.checkRadioNull, trigger: 'change' }
    ],
    cityBirth: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: 'blur' },
      { validator: this.checkHasChinese, trigger: 'blur' },
    ],
    fkStudentAppCountryId: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: 'change' }
    ],
    // fkStudentAppAncestryId: [
    //   { required: true, message: this.$t('validate.requiredField'), trigger: 'change' }
    // ],
    // fkStudentAppMotherLanguageId: [
    //   { required: true, message: this.$t('validate.requiredField'), trigger: 'change' }
    // ],
    fkStudentAppCountryIdBirth: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: 'change' }
    ],
    fkStudentAppCountryIdPassport: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: 'change' }
    ],
    passportIssueDate: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: ['change', 'blur'] }
    ],
    passportIssueExpried: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: ['change', 'blur'] }
    ],
    passportNumber: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: 'blur' },
    ],
    migrationTime: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: ['change', 'blur'] }
    ],
    stayingRightTime: [
      { required: !this.temporarySave, validator: this.checkIsEmpty, trigger: ['change', 'blur'] }
    ],
    sharedPath: [
      { required: true, validator: this.checkIsEmpty, trigger: 'change' },
    ],
    isPaymentOnBehalf: [
      { required: true, validator: this.checkRadioNull, trigger: 'change' },
    ],
  };

  public async created() {
    if (this.pageType === 'student-info') {
      this.getStudentRegisterData();
    } else {
      this.getAnalysisData()
    }
  }

  public mounted() {
    this.getCountryOptions();
    this.getPhoneRegionOptions();
    this.getStudentAncestryOptions();
    this.getModerLanguageOptions();
    this.validateShareParthRequired();
    this.getAgentEmail();
  }

  public beforeDestroy() {
    FileAnalysisModule.SET_PERSON_INFO({});
  }

  public async getAreaData(countryId: number | string) {
    countryId = Number(countryId);
    if (countryId !== 228) {
      this.stuFormData.fkAreaRegionIdFrom = '';
      return;
    }
    const res = await getAreaRegionByCountryId(7);
    this.areaOption = res.data.datas;
  }
  // 验证共享链接是否必填
  public async validateShareParthRequired(){
    if(this.bmsLoginId){
      const {data}:any=await getIsUseSharedPathApi(this.bmsLoginId);
      this.shareParthRequired=data.data;
    }
  }
  // 获取邮箱，自动填写
  public async getAgentEmail(){
    const {data}=await getAgentUserInfoByIdApi(this.onlineAgentUserId)
    if(data.code==='1003'){
      const agentInfo=data.data;
      this.stuFormData.followUpEmail1=agentInfo.email|| '';
      this.stuFormData.followUpPhoneCode1=agentInfo.mobileAreaCode||'';
      this.stuFormData.followUpPhoneNumber1=agentInfo.mobile||'';
    }
  }
  public async getStudentRegisterData() {
    const params = this.$route.params;
    const fkStudentId = +(params.stuid);
    const { data } = await getRegisterDataApi(fkStudentId);
    if (data.data) {
      const formData = this.setDataFormat(data.data);
      this.stuFormData = Object.assign(this.stuFormData, formData);
      this.status = data.data.status;
      this.getAreaOptions();
      this.getAreaData(this.stuFormData.fkStudentAppCountryIdFrom);
    }
  }

  public getAnalysisData() {
    const personInfo = FileAnalysisModule.personInfo;
    if(personInfo.fkGuidAndType&&Object.keys(personInfo.fkGuidAndType).length) {
      this.stuFormData.fkGuidAndTypeKey=personInfo.fkGuidAndType
    }
    const stateId = personInfo.fkAreaStateIdFrom;
    if (personInfo.passportNumber) {
      if (personInfo.fkStudentAppCountryIdFrom === 44) {
        this.getAreaSateOptions(44);
        this.selectChinese();
      } else if (personInfo.fkStudentAppCountryIdFrom === 92) {
        this.getAreaSateOptions(92);
        this.selectHongKong();
      }
      if (this.isNumber(stateId)) {
        this.getAreaCityOptions(+stateId)
      }
    }
    this.stuFormData = Object.assign(this.stuFormData, personInfo);
  }

  public setDataFormat(data: any) {
    for (const key in data) {
      if (data[key] && data[key] !== true && !isNaN(Number(data[key]))) {
        data[key] = Number(data[key])
      }
    }
    return data;
  }

  public getAreaOptions() {
    const ctry = this.stuFormData.fkStudentAppCountryIdFrom;
    const state = this.stuFormData.fkAreaStateIdFrom;
    const city = this.stuFormData.fkAreaCityIdFrom;
    if (this.isNumber(ctry)) {
      this.getAreaSateOptions(+ctry)
    }
    if (this.isNumber(state)) {
      this.getAreaCityOptions(+state)
    }
    if (this.isNumber(city)) {
      this.getRegionOptions(+city)
    }
  }

  public isNumber(value: any) {
    if (value && !isNaN(value)) {
      return true;
    }
    return false;
  }

  public checkHasChinese(rule: any, value: string, callback: any) {
    if (value && hasChinese(value)) {
      callback(new Error(this.$t('validate.englishNameValid') as string));
    } else {
      callback();
    }
  }
  // 验证生日不得小于12岁(紧提示)
  public checkBirthDay(rule: any, date: string, callback: any) {
    const nowDateY = (new Date()).getFullYear();
    const selDateY = (new Date(date)).getFullYear();
    if (nowDateY - selDateY < 12) {
      if(!this.birthdayFlag){
        this.birthdayFlag=true;
        setTimeout(()=>{
          this.$message.warning(this.$t('validate.birthdayValid') as any);
          this.birthdayFlag=false;
        },200)
      }
      callback();
    } else {
      callback();
    }
  }
  // 查看是否相同
  public async checkSameInfo(){
    const fkAgentId:any=UserModule.fkAgentId;
    if((this.stuFormData.nameZh&&this.stuFormData.birthday)||(this.stuFormData.nameZh&&this.stuFormData.passportNumber)){
      const sendData:any={
        nameZh:this.stuFormData.nameZh,
        birthday:this.stuFormData.birthday,
        passportNumber:this.stuFormData.passportNumber,
        fkAgentId,
      }
      const {data}:any=await validateStudentInfoApi(sendData);
      if(data.code==='1003'&&data.data&&data.data.length){
        this.repeatStudentList = data.data;
        const el:any=this.$refs.repeatStudentDialog;
        el.isShow=true;
      }
    }
  }
  public checkRegion(rule: any, date: string, callback: any) {
    if (this.stuFormData.followUpPhoneCode1 === '' && !this.temporarySave) {
      callback(
        new Error(
          `${this.$t('validate.requiredField')}${this.$t('stuManagement.followUpPhoneCode')}`
        )
      );
    } else {
      callback();
    }
  }

  public checkRadioNull(rule: any, value: string, callback: any) {
    if (value === '' || value === null) {
      callback(new Error(this.$t('validate.requiredField') as string));
    } else {
      callback();
    }
  }

  public checkIsEmpty(rule: any, value: string, callback: any) {
    const nameAbout = ['nameZh', 'lastName', 'firstName']
    let val;
    if (typeof value === 'string') {
      val = typeof value === 'string' ? value.trim() : value;
    } else if (typeof value === 'number') {
      val = Number(value);
    } else {
      val = value;
    }
    if (this.temporarySave) {
      if ((val !== 0) && !val && nameAbout.indexOf(rule.field) > -1) {
        callback(new Error(this.$t('validate.requiredField') as string));
      } else {
        callback();
      }
    } else {
      if ((val !== 0) && !val) {
        callback(new Error(this.$t('validate.requiredField') as string));
      } else {
        callback();
      }
    }
  }
  // 验证邮箱（多个邮箱）
  public  rulesEmail(rule: any, value: string, callback: any) {
    const emailArr: any = checkEmailList(value);// 分割
    let isEmail: boolean = true;
    if(rule.fullField==='followUpEmail3') {
      if (emailArr && emailArr.length) {
        for (const key of emailArr) {
          if (!key||!checkEmail2(key)) {
            isEmail = false;
            break;
          }
        }
      } else {
        if (value&&!checkEmail2(value)) {
          isEmail = false;
        }
      }
    } else {
      if (value&&!checkEmail2(value)) {
        isEmail = false;
      }
    }
    if(!value&&rule.fullField!=='followUpEmail1') {
      isEmail=true;
    }
    if(this.temporarySave) {
      isEmail=true;
    }
    if (isEmail) {
      callback();
    } else {
      callback(new Error(this.$t('validate.emailValid') as string));
    }
  }
  // 台湾限制 3-5，其他其他限制 5-6
  public checkContactPostcode(rule: any, value: string, callback: any) {
    const postcode: any = value.toString();
    if(this.stuFormData.fkStudentAppCountryIdFrom===211) {
      if(postcode.length >= 3 && postcode.length <= 6 || this.temporarySave) {
        callback();
      } else {
        callback(new Error(this.$t('validate.contactPostcodeTaiWanValid') as string));
      }
    } else if(this.stuFormData.fkStudentAppCountryIdFrom===228){
      if(postcode.length >= 5 && postcode.length <= 8 || this.temporarySave) {
        callback();
      } else {
        callback(new Error(this.$t('validate.contactPostcodeUKValid') as string));
      }
    }else {
      if (postcode.length !== 5 && postcode.length !== 6 && !this.temporarySave) {
        callback(new Error(this.$t('validate.contactPostcodeValid') as string));
      } else {
        callback();
      }
    }
  }

  // public checkStudentEmail(rule: any, value: string, callback: any) {
  //   const stuFormData = this.stuFormData;
  //   const contactsEmail = [stuFormData.followUpEmail1, stuFormData.followUpEmail2, stuFormData.followUpEmail3];
  //   if (value && contactsEmail.includes(value)) {
  //     return callback(new Error(this.$t('validate.stuEmailValid') as string));
  //   }
  // }

  public checkContactEmail(rule: any, value: string, callback: any) {
    const email = this.stuFormData.email;
    if (email && email === value) {
      return callback(new Error(this.$t('validate.stuEmailValid') as string))
    }
    callback()
  }

  public checkContactPhoneNumber(rule: any, value: string, callback: any) {
    const stuPhoneNumber = this.stuFormData.phoneNumber;
    if (stuPhoneNumber && stuPhoneNumber === value) {
      return callback(new Error(this.$t('validate.stuPhoneNumberValid') as string))
    }
    let phoneRegion;
    if (rule.fullField === 'followUpPhoneNumber1') {
      phoneRegion = this.stuFormData.followUpPhoneCode1;
    } else if (rule.fullField === 'followUpPhoneNumber2') {
      phoneRegion = this.stuFormData.followUpPhoneCode2;
    } else {
      phoneRegion = this.stuFormData.followUpPhoneCode3;
    }
    if (!phoneRegion && value) { return callback(new Error(this.$t('validate.phoneRegionValid2') as string)) }
    if (!this.phoneRegionValidate(phoneRegion, value)) {
      return callback(new Error(this.$t('validate.phoneRegionValid') as string));
    } else {
      this.stuform.clearValidate('phoneRegion');
      callback();
    }
  }

  public checkPhoneNumber(rule: any, value: string, callback: any) {
    const stuFormData = this.stuFormData;
    const contactsNumber = [stuFormData.followUpPhoneNumber1, stuFormData.followUpPhoneNumber2, stuFormData.followUpPhoneNumber3];
    if (value && contactsNumber.includes(value)) {
      return callback(new Error(this.$t('validate.stuPhoneNumberValid') as string));
    }
    const phoneRegion = this.stuFormData.phoneRegion;
    if (!phoneRegion) { return callback() };
    if (!this.phoneRegionValidate(phoneRegion, value)) {
      callback(new Error(this.$t('validate.phoneRegionValid') as string));
    } else {
      this.stuform.clearValidate('phoneRegion');
      callback();
    }
  }

  public checkContactPhoneRegion(rule: any, value: string, callback: any) {
    let phone;
    let field;
    if (rule.fullField === 'followUpPhoneCode1') {
      field = 'followUpPhoneCode1';
      phone = this.stuFormData.followUpPhoneNumber1;
    } else if (rule.fullField === 'followUpPhoneCode2') {
      field = 'followUpPhoneCode2';
      phone = this.stuFormData.followUpPhoneNumber2;
    } else {
      field = 'followUpPhoneCode3';
      phone = this.stuFormData.followUpPhoneNumber3;
    }
    if (!phone && value) { return callback(new Error(this.$t('validate.phoneRegionValid3') as string)) };
    if (!this.phoneRegionValidate(value, phone)) {
      this.stuform.validateField(field, callback(this.$t('validate.phoneRegionValid') as string));
      callback();
    } else {
      this.stuform.clearValidate(field);
      callback();
    }
  }

  public checkPhoneRegion(rule: any, value: string, callback: any) {
    const phoneNumber = this.stuFormData.phoneNumber;
    if (!phoneNumber) { return callback() };
    if (!this.phoneRegionValidate(value, phoneNumber)) {
      this.stuform.validateField('phoneNumber', callback(this.$t('validate.phoneRegionValid') as string));
      callback();
    } else {
      this.stuform.clearValidate('phoneNumber');
      callback();
    }
  }

  public checkAddrLength(rule: any, value: string, callback: any) {
    if (value && value.length > 45) {
      return callback(new Error(this.$t('validate.checkRgstAddress') as string))
    }
    callback();
  }

  public phoneRegionValidate(region: string, phone: string) {
    let phoneReg: RegExp | null = null;
    switch (region) {
      case '86': // 中国大陆：开头1 3-8号段，后边跟9位数字
        phoneReg = /^[1][3,4,5,7,8,9,6][0-9]{9}$/;
        break;
      case '852': // 5或9或6开头后面跟7位数字
        phoneReg = /^([4|5|6|7|9])\d{7}$/;
        break;
      case '853': // 6开头后面跟7位数字
        phoneReg = /^[6]\d{7}$/;
        break;
      case '886': // 9(或09)开头后面跟8位数字
        phoneReg = /^([0]?[9])\d{8}$/;
        break;
      case '61': // 澳大利亚4(或04)开头, 后面跟8位数
        phoneReg = /^([0]?[4])\d{8}$/;
        break;
      case '44': // 英国电话号码是7(或07)开头的, 后面跟9位数
        phoneReg = /^([0]?[7])\d{9}/;
        break;
    }
    if (phoneReg) {
      return phoneReg.test(phone);
    }
    return true;
  }

  public async getPhoneRegionOptions() {
    const { data } = await getPhoneRegionsApi();
    this.regionNumOptions = data.datas;
  }

  public async getCountryOptions() {
    const { data } = await getAppCountryApi();
    this.countryOptions = data.datas || [];
  }

  public async getAreaSateOptions(country: number) {
    const { data } = await getAreaSateApi(country);
    this.stateOptions = data.datas || [];
  }

  public async getAreaCityOptions(state: number) {
    const { data } = await getAreaCityApi(state)
    this.cityOptions = data.datas || [];
  }

  public async getRegionOptions(city: number) {
    const { data } = await getRegionOptionsApi(city);
    this.regionOptions = data.datas || [];
  }

  public async getStudentAncestryOptions() {
    const { data } = await getStudentAncestryApi();
    this.ancestryOptions = data.datas;
  }

  public async getModerLanguageOptions() {
    const { data } = await getModerLanguageApi();
    this.languageOptions = data.datas;
  }

  public handleGenderRadio(gender: number) {
    this.stuFormData.title = gender === 1 ? 1 : 2;
  }

  public handleSinceBirthRadio() {
    this.stuFormData.migrationTime = '';
    this.stuFormData.stayingRightTime = '';
  }

  public async handleSelectCountry(country: string | number) {
    this.stuFormData.fkAreaStateIdFrom = '';
    this.stuFormData.fkAreaRegionIdFrom = '';
    this.stuFormData.fkAreaCityIdFrom = '';
    this.stuFormData.fkAreaCityDivisionIdFrom = '';
    this.stateOptions = [];
    this.cityOptions = [];
    this.regionOptions = [];
    if (typeof country === 'number') {
      this.getAreaData(country);
      this.getAreaSateOptions(country);
      if (country === 44) {
        this.selectChinese();
      } else if (country === 92) {
        this.selectHongKong();
      }
    }
  }
  // 国家或地区为中国，则某些字段默认中国
  public selectChinese() {
    const stuFormData = this.stuFormData;
    if (!stuFormData.fkStudentAppCountryId) {
      stuFormData.fkStudentAppCountryId = 44;
    }
    if (!stuFormData.fkStudentAppAncestryId) {
      stuFormData.fkStudentAppAncestryId = 7;
    }
    if (!stuFormData.fkStudentAppMotherLanguageId) {
      stuFormData.fkStudentAppMotherLanguageId = 1;
    }
    if (!stuFormData.fkStudentAppCountryIdBirth) {
      stuFormData.fkStudentAppCountryIdBirth = 44;
    }
  }

  public selectHongKong() {
    const stuFormData = this.stuFormData;
    if (!stuFormData.phoneRegion) {
      stuFormData.phoneRegion = '852';
    }
    if (!stuFormData.contactPostcode) {
      stuFormData.contactPostcode = '000000'
    }
    if (!stuFormData.fkStudentAppCountryId) {
      stuFormData.fkStudentAppCountryId = 44;
    }
    if (!stuFormData.fkStudentAppAncestryId) {
      stuFormData.fkStudentAppAncestryId = 7;
    }
    if (!stuFormData.fkStudentAppMotherLanguageId) {
      stuFormData.fkStudentAppMotherLanguageId = 3;
    }
    if (!stuFormData.fkStudentAppCountryIdBirth) {
      stuFormData.fkStudentAppCountryIdBirth = 92;
    }
    if (!stuFormData.cityBirth) {
      stuFormData.cityBirth = 'Hong Kong';
    }
  }

  public handleSelectState(state: number | string) {
    this.stuFormData.fkAreaStateIdFrom = state;
    this.stuFormData.fkAreaCityIdFrom = '';
    this.stuFormData.fkAreaCityDivisionIdFrom = '';
    this.cityOptions = [];
    this.regionOptions = [];
    if (state && !isNaN(Number(state))) {
      this.getAreaCityOptions(+state);
    }
  }

  public handleSelectCity(city: number) {
    this.stuFormData.fkAreaCityIdFrom = city;
    this.regionOptions = [];
    if (city && !isNaN(Number(city))) {
      this.getRegionOptions(city);
    }
  }

  public handleSelectRegion(region: number) {
    if (region && !isNaN(Number(region))) {
      this.stuFormData.fkAreaCityDivisionIdFrom = region;
    }
  }

  // 是否有护照否时清数据
  public isHavePassportChange(val: boolean) {
    if (!val) {
      this.stuFormData.fkStudentAppCountryIdPassport = '';
      this.stuFormData.passportNumber = '';
      this.stuFormData.passportIssueExpried = '';
      this.stuFormData.passportIssueDate = '';
    }
  }

  public handleIssueDate(value: string) {
    const dateStr = this.stuFormData.passportIssueExpried;
    const issueDate = new Date(value).getTime();
    const issueExpriedDate = new Date(dateStr).getTime();
    if (dateStr && (issueDate > issueExpriedDate)) {
      this.$alert(
        this.$t('validate.issueDateValid', { issueDate: '(' + value + ')' }) + '',
        '',
        {
          confirmButtonText: this.$t('common.confirm') + '',
        }
      );
      this.stuFormData.passportIssueDate = '';
    }
  }

  public handleIssueExpried(value: string) {
    if (value) {
      const dateStr = this.stuFormData.passportIssueDate;
      const issueDate = new Date(dateStr).getTime();
      const date = new Date(dateStr);
      date.setMonth(date.getMonth() + 6);
      const leastDateStamp = date.getTime();
      const issueExpriedDate = new Date(value).getTime();
      if (dateStr && (issueDate > issueExpriedDate)) {
        // 签证日期不能在有效日期后
        this.$alert(
          this.$t('validate.issueDateValid', {
            expriDate: '(' + value + ')',
          }) + '',
          '',
          {
            confirmButtonText: this.$t('common.confirm') + '',
          }
        );
        this.stuFormData.passportIssueExpried = '';
        // 在有效期要在半年后
      } else if (issueExpriedDate < leastDateStamp) {
        this.$alert(this.$t('validate.checkPasswordTime') + '', '', {
          confirmButtonText: this.$t('common.confirm') + '',
        });
        this.stuFormData.passportIssueExpried = '';
      }
    }
  }
  // 暂存
  public async handleSaveTemporal() {
    if (this.status === 1) {
      this.$message.info(this.$t('message.immutableInfo') as string);
      return;
    }
    this.temporarySave = true;
    this.stuform.validate(async (valid: any, invalidFields: any) => {
      if (valid) {
        const stuid = await this.submitForm();
        this.temporarySave = false;
        if (stuid) {
          this.saveDisabled = true;
          this.stuId = stuid;
          // this.$message.success(this.$t('message.saveSuccessMsg') as string);
          const agentId = UserModule.fkAgentId;
          this.$router.push({name: 'StudentApplicationForm', params: { stuid }, query: { agid: agentId + '' }});
          FileAnalysisModule.SET_PERSON_INFO({});
        }
      } else {
        this.temporarySave = false;
        const keys = Object.keys(invalidFields);
        const invalidFieldFirstMsg = invalidFields[keys[0]][0].message;
        this.$message.error(invalidFieldFirstMsg);
      }
    })
  }
  // 按钮已屏蔽
  public async  handleSubmit() {
    this.temporarySave = false;
    if (this.status === 1) {
      this.$message.info(this.$t('message.immutableInfo') as string);
      return;
    }
    this.stuform.validate(async (valid: any, invalidFields: any) => {
      if (valid) {
        const stuid = await this.submitForm();
        if (stuid) {
          this.saveDisabled = true;
          this.stuId = stuid;
          this.$message.success(this.$t('message.saveSuccessMsg') as string);
          FileAnalysisModule.SET_PERSON_INFO({});
        }
      } else {
        const keys = Object.keys(invalidFields);
        const invalidFieldFirstMsg = invalidFields[keys[0]][0].message;
        this.$message.error(invalidFieldFirstMsg);
      }
    })
  }
  // 正常保存
  public async handleFillApply() {
    this.temporarySave = false;
    if (this.status === 1) {
      this.$message.info(this.$t('message.immutableInfo') as string);
      return;
    }
    this.stuform.validate(async (valid: any, invalidFields: any) => {
      if (valid) {
        let stuid = null;
        if (this.stuId) {
          stuid = this.stuId;
        } else {
          stuid = await this.submitForm();
        }
        if (stuid) {
          const agentId = UserModule.fkAgentId;
          this.$router.push({name: 'StudentApplicationForm', params: { stuid }, query: { agid: agentId + '' }});
          FileAnalysisModule.SET_PERSON_INFO({});
        }
      } else {
        const keys = Object.keys(invalidFields);
        const invalidFieldFirstMsg = invalidFields[keys[0]][0].message;
        this.$message.error(invalidFieldFirstMsg);
      }
    });
  }
  public async submitForm() {
    const agentId = UserModule.fkAgentId;
    this.stuFormData.fkAgentId = agentId;
    const stuData:any = await validateStudentInfoApi(this.stuFormData);
    this.repeatStudentList=[];
    if(stuData.data.code==='1003'&&stuData.data.data&&stuData.data.data.length&&this.pageType==='student-register') {
      this.repeatStudentList = stuData.data.data;
      const el:any=this.$refs.repeatStudentDialog;
      el.isShow=true;
    } else {
      const { data } = await saveOrUpdateStudentInfoApi(this.stuFormData);
      return Promise.resolve(data.data);
    }
  }

  public handleReset() {
    this.stuform.resetFields();
  }
}
