import request from '@/utils/request_login';
import { transformRequest } from '@/utils/request_config';

// 获取个人信息
export const getUserApi = () => request({
  url: `/authentication/oauth/getUser`,
  method: 'get',
});
// 切换语言传递给后端
export const getSetLanguageCodeToCacheApi = (languageCode: string) => request({
  url: `/issue/student/getSetLanguageCodeToCache/?languageCode=${languageCode}`,
  method: 'get',
});
// 新架构登录接口
export const loginApi = (params: any) => request({
  url: '/authentication/oauth/token',
  method: 'post',
  params,
  // 定义对于给定的HTTP 响应状态码是 resolve 或 reject  promise 。
  // 如果 `validateStatus` 返回 `true` (或者设置为 `null` 或 `undefined`)，promise 将被 resolve; 否则，promise 将被 rejecte
  // validateStatus(status) {
  //   return status >= 200 && status < 500
  // }
});
// 新架构刷新token
export const refreshTokenApi = (refreshToken: any) => request({
  url: '/authentication/oauth/token',
  method: 'post',
  data: {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
  },
  transformRequest
});

// 登出
export const logoutApi = () => request({
  url: '/authentication/oauth/logout',
  method: 'get',
});

// 注册
export const registerApi = (data: any) => request({
  url: '/appUser/issueRegister',
  method: 'post',
  data
})

// 修改密码
export const modifyPwdApi = (data: any) => request({
  url: '/appUser/modifyPwd',
  method: 'post',
  data
})

// 获取验证码
export const getVerificationCodeApi = (data: { mobile: string, type: string, mobileAreaCode: string }) => request({
  url: '/appUser/verification',
  method: 'post',
  data: Object.assign(data, { fkPlatformType: 'get_issue' })
})

// // bms代理跳转登录
// export const bmsAgentLoginApi = ({ fkUserId, key = 'cDB8Ulr8qTdcp0DxGg08xQ==' }: { fkUserId: number, key?: string }) => request({
//   url: '/appUser/getBmsAgentLogin',
//   method: 'get',
//   params: { fkUserId, key }
// })

// 手机区号
export const getPhoneRegionApi = () => request({
  url: '/issue/studentCountry/getPhoneRegion',
  method: 'get',
})

// 国家
export const getAllAreaCountryListApi = () => request({
  url: '/appInstitution/areaCountry/getAllAreaCountryList',
  method: 'get',
})

// 州省
export const getByCountryIdApi = (id: number) => request({
  url: `/appInstitution/areaState/getByCountryId/${id}`,
  method: 'get',
})

// 城市
export const getByFkAreaStateIdApi = (id: number) => request({
  url: `/appInstitution/areaCity/getByFkAreaStateId/${id}`,
  method: 'get',
})
// 获取issue用户权限
export const getIssueUserRootApi = () => request({
  url: `/issue/student/getIssueUserRoot`,
  method: 'get',
})

// 获取issue模块权限
export const getIssueRootApi = () => request({
  url: '/issue/summary/getIssueRoot',
  method: 'get'
})
// 重置密码
export const updatePwdByIssueApi = (data: any) => request({
  url: '/appUser/updatePwdByIssue',
  method: 'post',
  data
})
// 验证账号是否需要修改密码
export const checkLoginIsModifyPsApi = (params: any) => request({
  url: '/appUser/checkLoginIsModifyPs',
  method: 'get',
  params
})
// 查询代理详情信息
export const getAgentUserInfoByIdApi = (onlineAgentUserId:any) => request({
  url: '/appUser/getAgentUserInfoById',
  method: 'get',
  params:{onlineAgentUserId},
})
